<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import GenericError from '~/components/user-interface/generic-error.vue';
import { trackingMethods } from '~~/plugins/analytics.client';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputText from 'primevue/inputtext';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ExternalIcon from '../design-system/icons/ExternalIcon.vue';

const image = useImagePath;
const { $gameService } = useServices();
const selectedButton = ref<string>('All');
const searchText = ref<string>('');
const searching = ref<boolean>(false);
const showCards = ref<boolean>(false);
const showCategories = ref<boolean>(false);
const menu = ref<string[]>([]);
const errorMessage = ref<string>('sorry-cant-find-results');
const searchedGames = reactive({ games: [] });
const filterGames = reactive({ games: [] });
const fullSearchResponse = reactive({ data: [] });

const search = ref<HTMLElement>();

function selectCategory(id: string) {
  selectedButton.value = id;
  if (id !== 'All') {
    const filter = fullSearchResponse.data.filter(function (verticalData) {
      return (
        verticalData.vertical.toLocaleLowerCase() === id.toLocaleLowerCase()
      );
    });
    filterGames.games = filter[0].data;
  } else {
    filterGames.games = searchedGames.games;
  }
}

function reset() {
  selectedButton.value = 'All';
  searchText.value = '';
  showCards.value = false;
  showCategories.value = false;
}

// make an api call and get data
async function fetchSearchedGames(queryParam: string) {
  const query = queryParam.trim();
  searching.value = true;
  if (query === '') {
    // send GA tracking method for Empty Search Query
    searchText.value = '';
    await trackingMethods.searchQueryEmpty();
    errorMessage.value = 'please-enter-search';
    searching.value = false;
    filterGames.games = [];
    //  fetchAllGames();
  } else {
    // send GA tracking method with Search Query
    await trackingMethods.searchQuery(query);
    $gameService
      .fetchSearchedGames({
        search: query,
        languageCode: 'en-US',
        channel: 'WebDesktop',
        skip: 0,
        limit: 0,
        currency: 'USD',
      })
      .then(
        (data) => {
          searching.value = false;
          if (data.total < 1) {
            errorMessage.value = 'no-results-found';
            showCategories.value = false;
            return;
          }
          showCategories.value = true;
          fullSearchResponse.data = data.verticals.filter((vertical) => {
            return vertical.vertical !== 'Certification';
          });
          searchedGames.games = data.verticals[0].data.filter((game) => {
            return game.vertical !== 'Certification';
          });
          menu.value = [];

          data.verticals.forEach((vert) => {
            if (vert.vertical.toLocaleLowerCase() !== 'certification') {
              menu.value.push(vert.vertical);
            }
          });
          selectedButton.value = menu.value[0];
          selectCategory(selectedButton.value);
          // send GA tracking method with Search Results Success
          trackingMethods.searchResultSuccess(data.total);
        },
        (err) => {
          console.error(err);
          // send GA tracking method with Search Results Failure
          trackingMethods.searchFailure(err.message);
          fetchSearchedGames('featured');
          searching.value = false;
        }
      );
    showCards.value = true;
  }
}

function clearText() {
  searchText.value = '';
}

onMounted(() => {
  showCards.value = false;
  document.querySelector('#search')?.focus();
});

//live search
watch(
  () => searchText.value,
  (newText, oldText) => {
    // filterGames.games = [];
    if (searchText.value.length > 2 && newText.length >= oldText.length) {
      /* start debounce from 3 characters length and make sure debounce is not
      firing when slowly deleting search text  - *!*!*! potentially need to remove this unless they explicitly want an enter keypress
      when deleting chars for a search to take place*/
      debounceSearch();
    }
  }
);

const debounceSearch = debounce(() => {
  fetchSearchedGames(searchText.value);
}, 500);

function debounce(func, timeout) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
</script>

<template>
  <div class="h-full">
    <div class="p-2">
      <InputGroup>
        <InputGroupAddon class="border-r">
          <ExternalIcon
            class="ml-2 w-6 mx-auto"
            icon-name="search-magnifying"
          />
        </InputGroupAddon>
        <InputText
          id="search"
          class="dark:text-white"
          ref="search"
          v-model="searchText"
          :placeholder="$t(`search-games`)"
          :label="$t(`search-games`)"
          @keyup.enter="fetchSearchedGames(searchText)"
        />
        <InputGroupAddon
          v-if="!!searchText"
          class="bg-primary-layer text-base"
          @click="searchText = ''"
        >
          <div class="rounded-full bg-primary-layer-alternative p-1">
            <XMarkIcon class="w-5" />
          </div>
        </InputGroupAddon>
      </InputGroup>
    </div>
    <nav
      v-if="showCategories"
      class="flex items-center pl-2 pt-1 pb-2 gap-1"
      style="overflow: auto"
    >
      <div v-for="(item, index) in menu" :key="index">
        <Button
          class="text-sm md:text-md w-fit"
          style="block-size: fit-content"
          rounding="md"
          :type="item === selectedButton ? 'primary' : 'transparent'"
          @click="selectCategory(item)"
        >
          {{ $t(`vertical.${item.replaceAll(' ', '-').toLowerCase()}`) }}
        </Button>
      </div>
    </nav>
    <div v-if="showCards" class="p-2">
      <div class="flex justify-between">
        <p class="font-bold text-lg text-base-priority">
          {{ $t('results') }}
        </p>
        <Button class="text-xs" type="tertiary" @click="reset">
          {{ $t('reset') }}
        </Button>
      </div>
      <div class="overflow-auto h-full">
        <LazyUserInterfaceGenericLoader v-if="searching" />
        <div
          v-else-if="filterGames?.games?.length"
          class="grid grid-cols-3 gap-2"
        >
          <CategoryCard
            v-for="(game, index) in filterGames.games"
            :id="game.id"
            :key="`${game.id}-${index}`"
            :image="image(game.tileImage)"
            :title="$t(game.name)"
            :subtitle="`${$t(Object.keys(game.categories)[0])} • ${$t(
              game.provider
            )}`"
            :badge-right="game.badgeLeft"
            :badge-left="game.badgeRight"
            :favorite="game.favorite"
            :game="game"
          />
        </div>
        <GenericError v-else-if="!searching" state="danger" class="mb-3">
          {{ $t(errorMessage) }}
        </GenericError>
      </div>
    </div>
    <div v-else class="my-1" />
  </div>
</template>

<style scoped></style>
